@media (max-width: 575px) {
  .contacts .map {
    height: 300px;
  }

  .home__item-title {
    font-size: 20px;
  }

  .home__item-value {
    font-size: 25px;
  }

  .home__item-value span {
    font-size: 16px;
  }

  .features__item h2 {
    font-size: 30px;
  }

  .about .about__info h3 {
    font-size: 22px;
  }

  .contacts__block-inner ul {
    flex-direction: column;
  }

  .contacts__block {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
    margin-bottom: 1rem;
  }

  .contacts__block-inner ul {
    margin: 0;
  }

  .slide .slide-main .swiper-slide {
    height: 250px;
  }
}

@media (max-width: 767px) {
  .slide-main .swiper-slide {
    height: 350px;
  }
  .home__item-slide .slide-home .swiper-slide {
    max-height: 350px;
  }

  .about__info h3 {
    font-size: 30px;
  }

  .about__info p {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .header__сallback {
    margin-right: 0;
  }
  .home__item-wrap {
    flex-direction: column;
  }

  .home__item-slide {
    width: 100%;
  }

  .home__item-info-inner {
    flex-direction: column;
  }

  .home__item-value-wrap {
    max-width: 100%;
  }

  .home__item-info-wrap {
    order: 2;
  }

  .home__item-value-wrap {
    flex-direction: row;
  }

  .slide-home .swiper-slide {
    max-height: 450px;
  }

  .home__item-img {
    margin-top: 1rem;
  }

  .home__item-slide .prev,
  .home__item-slide .next {
    top: 0;
  }

  .features {
    margin-top: 0;
    padding-top: 1rem;
  }

  .home__item-info-wrap {
    padding: 1rem;
  }


}

@media (max-width: 1400px) {
  .contacts__phones-block a,
  .contacts__email-block a {
    font-size: 25px;
  }
}

@media (max-width: 1199px) {
  .contacts__wrap {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .contacts__phones-block {
    margin: 0;
  }
}

.about {
  background-color: #f5f8fb;
  &__info {
    padding: 4rem 0;
    text-align: center;

    h3 {
      font-size: 35px;
      padding-bottom: 1rem;
    }

    p {
      font-size: 22px;
      margin-bottom: 2rem;
    }
  }
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  min-height: 100vh;
  max-height: 100%;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

.title-main {
  display: inline-block;
  font-weight: 500;
  position: relative;
  margin-bottom: 3rem;
  &::before {
    content: '';
    position: absolute;
    top: 2.4rem;
    left: 0;
    width: 17rem;
    height: 1.6rem;
    background-color: #17BED2;
    border-radius: 2px;
    width: 100%;
    display: block;
    height: 0.3rem;
    bottom: 1rem;
  }

  span {
    position: relative;
    z-index: 1;
  }
}


::selection {
  background: #17BED2;
  color: #fff;
}

::-moz-selection {
  background: #17BED2;
  color: #fff;
}

::-webkit-selection {
  background: #17BED2;
  color: #fff;
}






.nb-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  margin: 0;
  background: transparent;
  border-top: 4px solid #17BED2;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



/*--------------------------------------------------------------
# Preloader
// --------------------------------------------------------------*/

#preloader {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}


.main {
  margin-top: 76px;
  height: 100%;
  
}



.fancybox__container {
  z-index: 1060;
}




.footer {
    background-color: #f6f9fc;

    &__copyright-area {
        padding: 0.6rem 0;
        border-top: 1px solid #dcdcdc;
    }

    &__copyright-text {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
    }
}
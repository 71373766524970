@charset "UTF-8";
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  min-height: 100vh;
  max-height: 100%; }

img {
  max-width: 100%; }

a {
  text-decoration: none; }

ul {
  list-style: none;
  padding: 0; }

.title-main {
  display: inline-block;
  font-weight: 500;
  position: relative;
  margin-bottom: 3rem; }
  .title-main::before {
    content: '';
    position: absolute;
    top: 2.4rem;
    left: 0;
    width: 17rem;
    height: 1.6rem;
    background-color: #17BED2;
    border-radius: 2px;
    width: 100%;
    display: block;
    height: 0.3rem;
    bottom: 1rem; }
  .title-main span {
    position: relative;
    z-index: 1; }

::selection {
  background: #17BED2;
  color: #fff; }

::-moz-selection {
  background: #17BED2;
  color: #fff; }

::-webkit-selection {
  background: #17BED2;
  color: #fff; }

.nb-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  margin: 0;
  background: transparent;
  border-top: 4px solid #17BED2;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite; }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/*--------------------------------------------------------------
# Preloader
// --------------------------------------------------------------*/
#preloader {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999; }

.main {
  margin-top: 76px;
  height: 100%; }

.fancybox__container {
  z-index: 1060; }

.header__menu {
  margin: 0 auto; }

.header__сallback {
  background-color: #17BED2;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  margin-right: 2rem;
  font-weight: 500;
  border: 2px solid #17BED2;
  transition: 0.3s;
  text-align: center; }
  .header__сallback a {
    color: #fff; }
  .header__сallback:hover {
    background-color: #fff; }
    .header__сallback:hover a {
      color: #17BED2; }

.header__contacts {
  display: flex;
  align-items: center; }

.header .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #333333; }
  .header .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 3px;
    left: 10px;
    background-color: #F0005C;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s; }

.header__phone, .header__email {
  font-weight: 500;
  font-size: 16px; }
  .header__phone a, .header__email a {
    color: #17BED2;
    transition: all 0.3s ease-in-out 0s; }
    .header__phone a:hover, .header__email a:hover {
      color: #333333; }

.header__menu .nav-item {
  margin-right: 1rem; }
  .header__menu .nav-item:last-child {
    margin-right: 0; }
  .header__menu .nav-item .nav-link {
    position: relative; }
    .header__menu .nav-item .nav-link:hover {
      color: #17BED2; }

.header__navbar-brand-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.8rem; }

.headroom {
  will-change: transform;
  background-color: #fff; }

.headroom--pinned {
  transform: translateY(0);
  background-color: #fff;
  transition: all ease 0.3s; }

.headroom--unpinned {
  transform: translateY(-100%);
  transition: all ease 0.3s; }

.navbar-toggler {
  height: 45px;
  width: 45px;
  padding: 0.6rem;
  border: 2px solid #17BED2;
  border-radius: 0.2rem;
  transition: all ease 0.3s; }
  .navbar-toggler svg {
    color: #17BED2; }
  .navbar-toggler:focus {
    box-shadow: none;
    background-color: #17BED2; }
  .navbar-toggler:focus svg {
    color: #fff; }
  .navbar-toggler:hover {
    background-color: #17BED2; }
  .navbar-toggler:hover svg {
    color: #fff; }

.navbar-brand {
  position: relative;
  display: flex;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
  font-size: 22px; }
  .navbar-brand::before {
    content: '';
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(180deg, #fff 0%, #17BED2 100%);
    display: inline-block; }
  .navbar-brand span {
    font-size: 14px;
    font-weight: 500; }
  .navbar-brand:hover {
    color: #333333; }

.footer {
  background-color: #f6f9fc; }
  .footer__copyright-area {
    padding: 0.6rem 0;
    border-top: 1px solid #dcdcdc; }
  .footer__copyright-text {
    margin: 0;
    font-weight: 400;
    font-size: 14px; }

.btn-details {
  display: inline-block;
  padding: 0.65rem 1.25rem;
  border-radius: 0.2rem;
  color: #17BED2;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  border: 2px solid #17BED2; }
  .btn-details:hover {
    background: #17BED2;
    color: #fff; }

.btn-details__fill {
  background: #17BED2;
  display: inline-block;
  padding: 0.65rem 1.25rem;
  border-radius: 0.2rem;
  color: #fff;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  border: 2px solid #17BED2; }
  .btn-details__fill:hover {
    background: #fff;
    color: #17BED2; }

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 30px;
  bottom: 30px;
  z-index: 996;
  background: #17BED2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 0.2rem;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); }
  .back-to-top i {
    font-size: 22px;
    color: #fff; }
  .back-to-top:hover {
    transform: scale(1.1, 1.1) translate3d(0, 0, 0); }

.back-to-top.active {
  visibility: visible;
  opacity: 1; }

.btn--light {
  border-radius: 2px;
  border: 1px solid #dcdcdc;
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  font-size: 14px;
  text-align: start; }
  .btn--light:hover {
    border: 1px solid #b4b4b4; }

.slide-main {
  position: relative;
  overflow: hidden; }
  .slide-main .swiper-slide {
    height: 500px;
    background-size: cover;
    background-position: center bottom; }
  .slide-main .swiper-button-next {
    top: inherit;
    bottom: 0; }
  .slide-main .slide-next,
  .slide-main .slide-prev {
    position: absolute;
    z-index: 1;
    background-color: #17BED2;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .slide-main .slide-next i,
    .slide-main .slide-prev i {
      font-size: 22px;
      color: #fafafa; }
  .slide-main .slide-next {
    right: 0;
    bottom: 0; }
  .slide-main .slide-prev {
    right: 50px;
    bottom: 0; }

.slide-home {
  height: 100%; }

.features {
  position: relative;
  justify-content: space-evenly;
  margin-top: -5rem;
  z-index: 1; }
  .features__item {
    background-color: #fff;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 1rem;
    height: 100%;
    box-shadow: 0px 0px 30px rgba(81, 94, 125, 0.082);
    border-bottom: 3px solid #17BED2;
    transition: all .4s ease; }
    .features__item h2 {
      font-size: 40px; }
    .features__item p {
      font-size: 19px;
      font-weight: 500; }
    .features__item:hover {
      background-color: #17BED2;
      color: #fff; }

.offcanvas__menu {
  padding-top: 0.8rem; }
  .offcanvas__menu li {
    position: relative;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 1rem;
    text-transform: uppercase; }
  .offcanvas__menu a {
    z-index: 2;
    flex: 1;
    display: flex;
    transition: all 0.3s ease-in-out 0s;
    width: 100%;
    color: #333333; }
    .offcanvas__menu a:hover {
      color: #17BED2; }

.offcanvas__contacts {
  padding-top: 1rem;
  margin-bottom: 1rem;
  border-top: 1px solid #dcdcdc; }
  .offcanvas__contacts .phone {
    margin-bottom: 0.5rem; }
  .offcanvas__contacts a {
    flex: 1;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    color: #333333; }

.offcanvas__button {
  top: 10px;
  border: 0;
  width: 60px;
  position: absolute;
  right: 0;
  background-color: transparent;
  transition: all 0.3s ease-in-out 0s; }
  .offcanvas__button svg {
    color: #333333; }

.offcanvas {
  background-color: #f5f8fb; }

.btn-close::before {
  content: '';
  background-color: #333333; }

.modal__content {
  position: relative; }
  .modal__content .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -41px; }
  .modal__content .swiper-pagination-bullet {
    width: 15px;
    height: 15px; }
  .modal__content .swiper-pagination-bullet {
    background: transparent;
    border: 2px solid #F0005C;
    opacity: 1; }
  .modal__content .swiper-pagination-bullet-active {
    background: #F0005C; }

.modal__content-img {
  border-radius: 15px;
  overflow: hidden;
  position: relative; }

.modal__content-title {
  display: inline-block;
  border-radius: 5px;
  font-weight: 700;
  margin: 0 0 2rem; }

.modal__content-text {
  font-weight: 400; }

.modal__content-link {
  margin-bottom: 2rem; }
  .modal__content-link a {
    color: #F0005C;
    font-weight: 500;
    text-decoration: underline; }
    .modal__content-link a:hover {
      opacity: 0.7; }

.modal__content-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 8rem; }

.modal__content-img a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  z-index: 1;
  opacity: 0;
  transition: all 500ms ease; }

.modal__content-img:hover a::before {
  opacity: 1; }

.modal__slider-next {
  position: absolute;
  right: 0;
  z-index: 99; }
  .modal__slider-next i {
    font-size: 40px; }
  .modal__slider-next:hover i {
    color: #F0005C; }

.modal__slider-prev {
  position: absolute;
  left: 0;
  z-index: 99; }
  .modal__slider-prev i {
    font-size: 40px; }
  .modal__slider-prev:hover i {
    color: #F0005C; }

.modal__header-goals {
  position: relative;
  margin: 5rem 0;
  display: flex;
  align-items: center; }
  .modal__header-goals img {
    opacity: .07;
    position: absolute;
    width: 100px;
    height: 100px;
    left: 0;
    top: -35px; }
  .modal__header-goals .line {
    flex-grow: 1;
    height: 1px;
    background: #F0005C; }
  .modal__header-goals h5 {
    font-size: 1.6rem;
    font-weight: 700;
    width: auto;
    margin: 0;
    margin-right: 0px;
    margin-right: 1.2rem;
    padding-left: 0.5rem;
    position: relative; }

.modal__goal-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 20px 0; }
  .modal__goal-item .pink-cube {
    position: absolute;
    content: "";
    width: 30px;
    top: 40px;
    left: 0;
    height: 30px;
    background: #ECECEC; }
  .modal__goal-item .num {
    position: relative;
    margin-right: 20px;
    width: 30px;
    height: 30px;
    font-size: 2.85rem;
    font-weight: 800;
    line-height: 1em;
    padding-left: 0.4rem; }
  .modal__goal-item p {
    font-weight: 400; }

.modal__process-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid #dadada;
  padding: 1rem;
  transition: all 0.3s; }
  .modal__process-item p {
    text-align: center;
    font-size: 15px;
    font-weight: 400; }
  .modal__process-item i {
    font-size: 45px;
    color: #F0005C;
    padding-bottom: 0.5rem;
    display: block; }
  .modal__process-item:hover {
    border: 1px solid #F0005C; }

.modal__checkpoints-item {
  padding: 5px 0;
  padding-left: 0px;
  padding-left: 60px;
  background: url(../img/icons/check.png) no-repeat 0 -7px/55px 55px;
  margin-bottom: 30px;
  font-weight: 400; }

.modal__feedback .modal-body {
  padding-bottom: 2rem; }

.modal__feedback .title {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 2rem; }

.modal__feedback label {
  font-weight: 400; }

.modal__feedback .btn-close {
  top: 30px;
  right: 30px;
  width: 10px;
  height: 10px; }

.modal__feedback .form-control {
  padding: 0.8rem 1rem; }
  .modal__feedback .form-control:focus {
    box-shadow: none;
    border-color: #F0005C; }
  .modal__feedback .form-control:active {
    border-color: #F0005C; }
  .modal__feedback .form-control:hover {
    border-color: #F0005C; }

.modal__feedback .form-check-input:checked {
  background-color: #F0005C;
  border-color: #F0005C; }

.modal__feedback .form-check-input {
  background-color: transparent;
  border-color: #F0005C;
  border-radius: 0; }

.modal__feedback .form-check-input:focus {
  border-color: #F0005C;
  box-shadow: none; }

.modal__feedback .form-group {
  text-align: center; }

.modal .main__link {
  font-size: 20px;
  font-weight: 500; }

.modal-content {
  border-radius: 5px;
  background-color: #f7faff; }

.modal-header {
  border-bottom: 0; }

.modal-body {
  margin: 4rem;
  overflow: hidden;
  padding: 0 0 3rem; }

.modal-open .root {
  filter: blur(5px); }

.btn-close {
  position: absolute;
  top: 5px;
  right: -60px;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  background: url(../img/svg/close.svg) center no-repeat;
  min-width: inherit;
  z-index: 100;
  border: 0;
  cursor: pointer;
  outline: 0;
  opacity: 1; }
  .btn-close:focus {
    box-shadow: none; }

.swiper-case .swiper-container-horizontal {
  bottom: -35px; }

.swiper-button-disabled {
  opacity: 0.5; }

.home {
  padding: 4rem 0 0; }
  .home__item {
    margin-bottom: 3rem; }
    .home__item:last-child {
      margin-bottom: 0; }
  .home__item-wrap {
    display: flex;
    border-radius: 0.3rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; }
  .home__item-slide {
    position: relative;
    flex: 0 0 auto;
    width: 35%;
    overflow: hidden; }
    .home__item-slide img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .home__item-slide .next,
    .home__item-slide .prev {
      position: absolute;
      z-index: 1;
      background-color: #17BED2;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .home__item-slide .next i,
      .home__item-slide .prev i {
        font-size: 22px;
        color: #fafafa; }
    .home__item-slide .next {
      right: 0;
      bottom: 0; }
    .home__item-slide .prev {
      right: 50px;
      bottom: 0; }
  .home__item-info {
    flex: 1; }
  .home__item-img {
    display: flex; }
    .home__item-img li {
      margin-right: 1rem; }
    .home__item-img img {
      width: 100%;
      max-width: 120px;
      height: 70px;
      object-fit: cover;
      border-radius: 0.2rem;
      overflow: hidden; }
  .home__item-info-inner {
    display: flex;
    justify-content: space-between;
    height: 100%; }
  .home__item-value-wrap {
    background-color: #17BED2;
    padding: 1rem;
    max-width: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; }
  .home__item-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    color: #fff;
    font-weight: 500; }
    .home__item-value span {
      font-size: 18px;
      color: #333333; }
  .home__item-desc {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow-y: hidden; }
  .home__item-info-wrap {
    padding: 2rem 3rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .home__item-title {
    font-size: 25px;
    margin-bottom: 2rem; }

.about {
  background-color: #f5f8fb; }
  .about__info {
    padding: 4rem 0;
    text-align: center; }
    .about__info h3 {
      font-size: 35px;
      padding-bottom: 1rem; }
    .about__info p {
      font-size: 22px;
      margin-bottom: 2rem; }

.news {
  padding: 3rem 0; }
  .news .meta-details {
    margin-bottom: 0.5rem; }
    .news .meta-details a {
      font-size: 14px;
      font-weight: 500;
      color: #17BED2; }
  .news__item {
    border: 1px solid #eee;
    background: #fff;
    border-radius: 0.3rem;
    margin-bottom: 25px; }
    .news__item:hover img {
      transform: scale(1.1); }
  .news__content-body {
    padding: 1rem 2rem; }
  .news__item-title {
    font-size: 18px;
    font-weight: 600; }
    .news__item-title a {
      transition: all 0.4s ease;
      color: #333333; }
      .news__item-title a:hover {
        color: #17BED2; }
  .news__item-image {
    overflow: hidden;
    height: 250px; }
    .news__item-image img {
      width: 100%;
      height: 100%;
      transition: all 0.4s ease; }

.map {
  position: relative;
  height: 500px;
  overflow: hidden;
  border-radius: 0.2rem; }

#map {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;
  position: relative; }

.contacts {
  padding: 3rem 0;
  background-color: #f6f9fc; }
  .contacts__block-inner ul {
    display: flex;
    margin-bottom: 2rem; }
  .contacts__block-header {
    font-size: 0.9rem;
    line-height: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0; }
  .contacts__block {
    margin-right: 2rem;
    padding-right: 2rem;
    border-right: 1px solid #dcdcdc; }
    .contacts__block:last-child {
      border-right: 0;
      padding: 0;
      margin: 0; }
  .contacts__phones-block a, .contacts__email-block a {
    font-size: 30px;
    font-weight: 500;
    color: #333333;
    border-bottom: 1px solid rgba(30, 30, 30, 0.15);
    transition: color .1s,border-color .1s; }
    .contacts__phones-block a:hover, .contacts__email-block a:hover {
      border-bottom-color: rgba(30, 30, 30, 0.3); }
  .contacts__phones-block p, .contacts__email-block p {
    font-size: 14px;
    font-weight: 500; }
  .contacts__wrap {
    display: flex; }
  .contacts__phones-block {
    margin-right: 2rem; }

@media (max-width: 575px) {
  .contacts .map {
    height: 300px; }
  .home__item-title {
    font-size: 20px; }
  .home__item-value {
    font-size: 25px; }
  .home__item-value span {
    font-size: 16px; }
  .features__item h2 {
    font-size: 30px; }
  .about .about__info h3 {
    font-size: 22px; }
  .contacts__block-inner ul {
    flex-direction: column; }
  .contacts__block {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
    margin-bottom: 1rem; }
  .contacts__block-inner ul {
    margin: 0; }
  .slide .slide-main .swiper-slide {
    height: 250px; } }

@media (max-width: 767px) {
  .slide-main .swiper-slide {
    height: 350px; }
  .home__item-slide .slide-home .swiper-slide {
    max-height: 350px; }
  .about__info h3 {
    font-size: 30px; }
  .about__info p {
    font-size: 18px; } }

@media (max-width: 991px) {
  .header__сallback {
    margin-right: 0; }
  .home__item-wrap {
    flex-direction: column; }
  .home__item-slide {
    width: 100%; }
  .home__item-info-inner {
    flex-direction: column; }
  .home__item-value-wrap {
    max-width: 100%; }
  .home__item-info-wrap {
    order: 2; }
  .home__item-value-wrap {
    flex-direction: row; }
  .slide-home .swiper-slide {
    max-height: 450px; }
  .home__item-img {
    margin-top: 1rem; }
  .home__item-slide .prev,
  .home__item-slide .next {
    top: 0; }
  .features {
    margin-top: 0;
    padding-top: 1rem; }
  .home__item-info-wrap {
    padding: 1rem; } }

@media (max-width: 1400px) {
  .contacts__phones-block a,
  .contacts__email-block a {
    font-size: 25px; } }

@media (max-width: 1199px) {
  .contacts__wrap {
    flex-direction: column;
    margin-bottom: 2rem; }
  .contacts__phones-block {
    margin: 0; } }

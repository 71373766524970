.features {
  position: relative;
  justify-content: space-evenly;
  margin-top: -5rem;
  z-index: 1;
  &__item {
    background-color: $white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 1rem;
    height: 100%;
    box-shadow: 0px 0px 30px rgba(81, 94, 125, 0.082);
    border-bottom: 3px solid $blue;
    transition: all .4s ease;

    h2 {
        font-size: 40px;
    }

    p {
        font-size: 19px;
        font-weight: 500;
    }

    &:hover {
        background-color: $blue;
        color: $white;
    }
  }
}

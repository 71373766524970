.home {
    padding: 4rem 0 0;

  &__item {
      margin-bottom: 3rem;
      &:last-child {
          margin-bottom: 0;
      }
  }  

  &__item-wrap {
    display: flex;
    border-radius: 0.3rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  &__item-slide {
    position: relative;
    flex: 0 0 auto;
    width: 35%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .next,
    .prev {
      position: absolute;
      z-index: 1;
      background-color: $blue;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
          font-size: 22px;
          color: #fafafa;
      }
    }

    .next {
        right: 0;
        bottom: 0;
    }

    .prev  {
        right: 50px;
        bottom: 0;
    }
  }

  &__item-info {
    flex: 1;
  }

  &__item-img {
    display: flex;
    li {
      margin-right: 1rem;
    }
    img {
      width: 100%;
      max-width: 120px;
      height: 70px;
      object-fit: cover;
      border-radius: 0.2rem;
      overflow: hidden;
    }
  }

  &__item-info-inner {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  &__item-value-wrap {
    background-color: $blue;
    padding: 1rem;
    max-width: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__item-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    color: $white;
    font-weight: 500;
    span {
      font-size: 18px;
      color: $dark-gray;
    }
  }

  &__item-desc {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  }

  &__item-info-wrap {
    padding: 2rem 3rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item-title {
    font-size: 25px;
    margin-bottom: 2rem;
  }
}

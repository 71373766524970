.modal {

  &__content {
    position: relative;

    .swiper-container-horizontal>.swiper-pagination-bullets {
      bottom: -41px;
    }

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
    }



    .swiper-pagination-bullet {
      background: transparent;
      border: 2px solid $pink;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: $pink;
    }




  }

  &__content-img {
    border-radius: 15px;
    overflow: hidden;
    position: relative;
  }

  &__content-title {
    display: inline-block;
    border-radius: 5px;
    font-weight: 700;
    margin: 0 0 2rem;
  }

  &__content-text {
    font-weight: 400;
  }

  &__content-link {
    margin-bottom: 2rem;

    a {
      color: $pink;
      font-weight: 500;
      text-decoration: underline;

      &:hover {
        opacity: 0.7;
      }
    }
    
  }

  &__content-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 8rem;
  }


  &__content-img a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    z-index: 1;
    opacity: 0;
    transition: all 500ms ease;


  }

  &__content-img {
    &:hover a::before {
      opacity: 1;
    }
  }

  &__slider-next {
    position: absolute;
    right: 0;
    z-index: 99;

    i {
      font-size: 40px;
    }

    &:hover {
      i {
        color: $pink;
      }
    }
  }

  &__slider-prev {
    position: absolute;
    left: 0;
    z-index: 99;

    i {
      font-size: 40px;
    }


    &:hover {
      i {
        color: $pink;
      }
    }

  }

  &__header-goals {
    position: relative;
    margin: 5rem 0;
    display: flex;
    align-items: center;

    img {
      opacity: .07;
      position: absolute;
      width: 100px;
      height: 100px;
      left: 0;
      top: -35px;
    }

    .line {
      flex-grow: 1;
      height: 1px;
      background: $pink;
    }

    h5 {
      font-size: 1.6rem;
      font-weight: 700;
      width: auto;
      margin: 0;
      margin-right: 0px;
      margin-right: 1.2rem;
      padding-left: 0.5rem;
      position: relative;

    }
  }

  &__goal-item {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 20px 0;

    .pink-cube {
      position: absolute;
      content: "";
      width: 30px;
      top: 40px;
      left: 0;
      height: 30px;
      background: #ECECEC;
    }

    .num {
      position: relative;
      margin-right: 20px;
      width: 30px;
      height: 30px;
      font-size: 2.85rem;
      font-weight: 800;
      line-height: 1em;
      padding-left: 0.4rem;
    }

    p {
      font-weight: 400;
    }
  }

  &__process-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid #dadada;
    padding: 1rem;
    transition: all 0.3s;

    p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
    }

    i {
      font-size: 45px;
      color: $pink;
      padding-bottom: 0.5rem;
      display: block
    }

    &:hover {
      border: 1px solid $pink;
    }
  }


  &__checkpoints-item {
    padding: 5px 0;
    padding-left: 0px;
    padding-left: 60px;
    background: url(../img/icons/check.png) no-repeat 0 -7px/55px 55px;
    margin-bottom: 30px;
    font-weight: 400;
  }

  &__feedback {

    .modal-body {
      padding-bottom: 2rem;
    }

    .title {
      text-align: center;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 2rem;
    }

    label {
      font-weight: 400;
    }

    .btn-close {
      top: 30px;
      right: 30px;
      width: 10px;
      height: 10px;
    }

    .form-control {
      padding: 0.8rem 1rem;
      &:focus {
        box-shadow: none;
        border-color: $pink;
      }

      &:active {
        border-color: $pink;
      }

      &:hover {
        border-color: $pink;
      }
    } 

    .form-check-input:checked {
      background-color: $pink;
      border-color: $pink;
    }

    .form-check-input {
      background-color: transparent;
      border-color: $pink;
      border-radius: 0;
    }

    .form-check-input:focus {
      border-color: $pink;
      box-shadow: none;
    }

    .form-group {
      text-align: center
    }

  }















  .main__link {
    font-size: 20px;
    font-weight: 500;
  }


}



.modal-content {
  border-radius: 5px;
  background-color: #f7faff;
}

.modal-header {
  border-bottom: 0;
}

.modal-body {
  margin: 4rem;
  overflow: hidden;
  padding: 0 0 3rem;
}

.modal-open .root {
  filter: blur(5px);
}


.btn-close {
  position: absolute;
  top: 5px;
  right: -60px;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  background: url(../img/svg/close.svg) center no-repeat;
  min-width: inherit;
  z-index: 100;
  border: 0;
  cursor: pointer;
  outline: 0;
  opacity: 1;


  &:focus {
    box-shadow: none;
  }
}

.swiper-case .swiper-container-horizontal {
  bottom: -35px;
}


.swiper-button-disabled {
  opacity: 0.5;
}

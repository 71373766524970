.news {
  padding: 3rem 0;
  .meta-details {
    margin-bottom: 0.5rem;
    a {
      font-size: 14px;
      font-weight: 500;
      color: $blue;
    }
  }

  &__item {
    border: 1px solid #eee;
    background: #fff;
    border-radius: 0.3rem;
    margin-bottom: 25px;

    &:hover img {
      transform: scale(1.1);
    }
  }

  &__content-body {
    padding: 1rem 2rem;
  }

  &__item-title {
    font-size: 18px;
    font-weight: 600;

    a {
      transition: all 0.4s ease;
      color: $dark-gray;
      &:hover {
        color: $blue;
      }
    }
  }

  &__item-image {
    overflow: hidden;
    height: 250px;
    img {
      width: 100%;
      height: 100%;
      transition: all 0.4s ease;
    }
  }
}

.offcanvas {
  &__menu {
    padding-top: 0.8rem;

    li {
      position: relative;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }

    a {
      z-index: 2;
      flex: 1;
      display: flex;
      transition: all 0.3s ease-in-out 0s;
      width: 100%;
      color: $dark-gray;

      &:hover {
        color: $blue;
      }
    }
  }

  &__contacts {
    padding-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px solid #dcdcdc;

    .phone {
      margin-bottom: 0.5rem;
    }

    a {
      flex: 1;
      display: flex;
      font-size: 20px;
      font-weight: 500;
      color: $dark-gray;
    }
  }





  &__button {
    top: 10px;
    border: 0;
    width: 60px;
    position: absolute;
    right: 0;
    background-color: transparent;
    transition: all 0.3s ease-in-out 0s;

    svg {
      color: $dark-gray;
    }
  }


}

.offcanvas {
  background-color: #f5f8fb;
}



.btn-close {
  &::before {
    content: '';
    background-color: $dark-gray;
  }
}

.btn-details {
  display: inline-block;
  padding: 0.65rem 1.25rem;
  border-radius: 0.2rem;
  color: $blue;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  border: 2px solid $blue;

  &:hover {
    background: $blue;
    color: #fff;
  }
}

.btn-details__fill {
  background: $blue;
  display: inline-block;
  padding: 0.65rem 1.25rem;
  border-radius: 0.2rem;
  color: $white;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  border: 2px solid $blue;

  &:hover {
    background: $white;
    color: $blue;
  }
}

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 30px;
  bottom: 30px;
  z-index: 996;
  background: $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 0.2rem;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);

  i {
    font-size: 22px;
    color: $white;
  }

  &:hover {
    transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  }
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

.btn--light {
  border-radius: 2px;
  border: 1px solid #dcdcdc;
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  font-size: 14px;
  text-align: start;
  &:hover {
    border: 1px solid #b4b4b4;
  }
}

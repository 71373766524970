.map {
  position: relative;
  height: 500px;
  overflow: hidden;
  border-radius: 0.2rem;
}

#map {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;
  position: relative;
}

.contacts {
  padding: 3rem 0;
  background-color: #f6f9fc;

  &__block-inner {


    ul {
        display: flex;
        margin-bottom: 2rem;
    }
  }

  &__block-header {
    font-size: 0.9rem;
    line-height: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }

  &__block {
    margin-right: 2rem;
    padding-right: 2rem;
    border-right: 1px solid #dcdcdc;
    &:last-child {
        border-right: 0;
        padding: 0;
        margin: 0;
    }
  }

  &__phones-block,
  &__email-block {
      a {
        font-size: 30px;
        font-weight: 500;
        color: $dark-gray;
        border-bottom: 1px solid rgba(30,30,30,.15);
        transition: color .1s,border-color .1s;
        &:hover {
            border-bottom-color: rgba(30,30,30,.3);
        }
      }

      p { font-size: 14px;
          font-weight: 500;
      }
      
  }

  &__wrap {
      display: flex;
  }

  &__phones-block {
      margin-right: 2rem;
  }
}

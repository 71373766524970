.header {

  &__menu {
    margin: 0 auto;
  }

  &__сallback {
    background-color: $blue;
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;
    margin-right: 2rem;
    font-weight: 500;
    border: 2px solid $blue;
    transition: 0.3s;
    text-align: center;

    a {
      color: $white;
    }

    &:hover {
      background-color: $white;
      a {
        color: $blue;
      }
      
    }

  }

  &__contacts {
    display: flex;
    align-items: center;

  }

  .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: $dark-gray;

    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 3px;
      left: 10px;
      background-color: $pink;
      visibility: hidden;
      width: 0px;
      transition: all 0.3s ease-in-out 0s;
    }


  }



  &__phone,
  &__email {
    font-weight: 500;
    font-size: 16px;

    a {
      color: $blue;
      transition: all 0.3s ease-in-out 0s;

      &:hover {
        color: $dark-gray;
      }
    }



  }



  &__menu {
    .nav-item {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }


    

    .nav-link {
      position: relative;

      &:hover {
        color: $blue;
      }

 


    }

  }





}





&__navbar-brand-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.8rem;
}






}

.headroom {
  will-change: transform;
  background-color: #fff;
}

.headroom--pinned {
  transform: translateY(0);
  background-color: #fff;
  transition: all ease 0.3s;

}

.headroom--unpinned {
  transform: translateY(-100%);
  transition: all ease 0.3s;
}



.navbar-toggler {
  height: 45px;
  width: 45px;
  padding: 0.6rem;
  border: 2px solid $blue;
  border-radius: 0.2rem;
  transition: all ease 0.3s;

  svg {
    color: $blue;
  }

  &:focus {
    box-shadow: none;
    background-color: $blue;
  }

  &:focus svg {
    color: $white;
  }

  &:hover {
    background-color: $blue;
  }

  &:hover svg {
    color: $white;
  }

}

.navbar-brand {
  position: relative;
  display: flex;
  font-weight: 500;
  color: $dark-gray;
  line-height: 25px;
  font-size: 22px;


  &::before {
    content: '';
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(180deg, #fff 0%, #17BED2 100%);
    display: inline-block;
  }

  span {
    font-size: 14px;
    font-weight: 500;
  }

  &:hover {
    color: $dark-gray;
  }
}